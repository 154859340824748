import { TableProps } from 'antd'
import { ColumnGroupType, ColumnsType, ColumnType } from 'antd/es/table'
import { FC, ReactNode } from 'react'
import { AnyObject, isNumber, isObject, isString, ResultStatus } from '@netpurpose/types'
import { isEqual } from '@netpurpose/utils'
import { Spinner, TableDetailButton } from '..'
import { TableDetailLinkContainer } from '../TableDetailButton/TableDetailButton.style'
import { LinkText } from './NavigationTable.style'
import Table from './Table'

type Link<RecordType> = FC<{ record: RecordType; children: React.ReactNode }>
type ShouldRenderLink<RecordType> = (record: RecordType) => boolean

const isDataIndexMatch = <RecordType extends AnyObject>(
  dataIndex: unknown,
  column: ColumnType<RecordType> | ColumnGroupType<RecordType>,
) => {
  if (!('dataIndex' in column)) {
    return false
  }
  const columnDataIndex = column.dataIndex

  if (Array.isArray(dataIndex)) {
    return Array.isArray(columnDataIndex) && isEqual(dataIndex, columnDataIndex)
  }

  return dataIndex === columnDataIndex
}

const getIndexValue = <RecordType extends AnyObject>(
  dataIndex: ColumnType<RecordType>['dataIndex'],
  record: RecordType,
) => {
  if (isString(dataIndex) || isNumber(dataIndex)) {
    return record[dataIndex]
  }

  return (
    dataIndex &&
    dataIndex.reduce(
      (value: unknown, index) => (isObject(value) ? value[index] : undefined),
      record,
    )
  )
}

const addLinkToColumn = <RecordType extends { resultStatus?: ResultStatus }>(
  linkColumnDataIndex: ColumnType<RecordType>['dataIndex'],
  givenColumns: ColumnsType<RecordType>,
  renderLink: Link<RecordType>,
  shouldRenderLink: ShouldRenderLink<RecordType>,
  withColumnGroup?: boolean,
) => {
  const addLink = (columns: ColumnsType<RecordType>) =>
    columns.map((column) => {
      if (!isDataIndexMatch(linkColumnDataIndex, column)) {
        return column
      }

      return {
        ...column,
        render: (value: RecordType[keyof RecordType], record: RecordType, index: number) => {
          if (record.resultStatus !== undefined && record.resultStatus !== 'complete') {
            return value as ReactNode
          }

          if (value === undefined || !shouldRenderLink(record)) {
            return value as ReactNode
          }

          return renderLink({
            record,
            children: column.render
              ? (column.render(value, record, index) as ReactNode)
              : (value as ReactNode),
          })
        },
      }
    })

  return withColumnGroup
    ? givenColumns
        .filter((item): item is ColumnGroupType<RecordType> => !!item)
        .map((col) => ({ ...col, children: addLink(col.children) }))
    : addLink(givenColumns)
}

const getDetailButtonColumn = <RecordType extends AnyObject>(
  linkColumnDataIndex: ColumnType<RecordType>['dataIndex'],
  renderLink: Link<RecordType>,
  shouldRenderLink: ShouldRenderLink<RecordType>,
  IconComponent?: FC,
  withColumnGroup?: boolean,
) => {
  const detailButtonColumn = {
    title: '',
    dataIndex: 'resultStatus',
    width: '1px', // Keep at min width needed for button
    render: (resultStatus: ResultStatus | undefined, record: RecordType) => {
      if (![undefined, 'complete', 'VERIFIED'].includes(resultStatus)) {
        return <Spinner size="small" />
      }

      const value = getIndexValue(linkColumnDataIndex, record)
      if (value === undefined || !shouldRenderLink(record)) {
        return ''
      }

      return renderLink({
        record,
        children: IconComponent ? (
          <TableDetailLinkContainer>
            <IconComponent />
          </TableDetailLinkContainer>
        ) : (
          <TableDetailButton />
        ),
      })
    },
  }
  return withColumnGroup
    ? {
        title: '',
        children: [detailButtonColumn],
      }
    : detailButtonColumn
}

type NavigationTableProps<RecordType> = TableProps<RecordType> & {
  renderLink?: Link<RecordType>
  renderLastColumnLink?: Link<RecordType>
  shouldRenderLink?: ShouldRenderLink<RecordType>
  linkColumnDataIndex?: ColumnType<RecordType>['dataIndex']
  IconComponent?: FC
  hideDetailButtonColumn?: boolean
  withColumnGroup?: boolean
  $withBottomPadding?: boolean
  $withTotalRow?: boolean
}

const NavigationTable = <RecordType extends AnyObject>({
  columns,
  renderLink,
  renderLastColumnLink,
  shouldRenderLink = () => true,
  linkColumnDataIndex,
  IconComponent,
  hideDetailButtonColumn,
  withColumnGroup = false,
  ...rest
}: NavigationTableProps<RecordType>) => {
  if (!columns) {
    return null
  }

  const renderColumnsWithLinks = renderLink && linkColumnDataIndex
  const columnsWithLink = renderColumnsWithLinks
    ? addLinkToColumn<RecordType>(
        linkColumnDataIndex,
        columns,
        renderLink,
        shouldRenderLink,
        withColumnGroup,
      )
    : columns

  // > button at the end of the row
  const detailButtonColumn =
    renderColumnsWithLinks && !hideDetailButtonColumn
      ? getDetailButtonColumn<RecordType>(
          linkColumnDataIndex,
          renderLastColumnLink || renderLink,
          shouldRenderLink,
          IconComponent,
          withColumnGroup,
        )
      : null

  const columnsToRender = detailButtonColumn
    ? [...columnsWithLink, detailButtonColumn]
    : columnsWithLink

  return <Table<RecordType> columns={columnsToRender} {...rest} />
}

LinkText.displayName = 'NavigationTable.LinkText'
NavigationTable.LinkText = LinkText

export default NavigationTable
